/* Rectangle 26 - Main Box */
.box {
    position: relative;
    width: 98%; /* Adjust width to 98% of the parent container */
    max-width: 407px; /* Maximum width to retain the original size */
    height: auto; /* Let the height adjust automatically based on content */
    min-height: 180px; /* Minimum height to maintain original height */
    left: 50%; 
    transform: translateX(-50%); /* Center horizontally */
    top: 10px;
    background: #ECFAFD;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 15px; /* Add some padding inside the box */
    box-sizing: border-box; /* Ensure padding is included in width and height calculation */
  }
  .boxicon {
    width: 36px;
    height: 36px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: auto; 
}

  
  /* Profile - Circular image or icon */
  .profile {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #D9D9D9;
    border-radius: 50%; /* Ensure the profile is circular */
  }
  
  /* Name */
  .name {
    position: absolute;
    max-width: 200px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 21.06px;
    color: #000000;
  }

  .truncate-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-word;
}
 .tooltip {
    position: absolute;
    z-index: 50;
    background-color: white;
    color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem; 
    white-space: normal;
    max-width: 300px;
    overflow-wrap: break-word; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    border: 1px solid #d1d5db; 
    margin: -40px 10px 0 80px;
  }
  .line {
    position: absolute;
    width: 90%;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #737373;
}
  .northstartbox {
    width: 100%; /* Adjusts to container width */
    max-width: 337px; /* Keeps the original size limit */
    height: auto; /* Adjust height based on content */
    padding: 10px; /* Adds inner spacing for better visual appearance */
    box-sizing: border-box; /* Ensures padding doesn't affect dimensions */
  }
  .northstar{
    /* left: 39px; */
    bottom: 68.88%;
    box-shadow: 0px 0px 8.316px #FFFFFF;
  }
  
  .northstartext {
    width: auto; /* Adjust width dynamically */
    height: auto; /* Adjust height dynamically */
    opacity: 1; /* Fix opacity to make text visible */
    line-height: 25px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #141736;
  }
  .custom-rectangle {
    box-sizing: border-box;
    left: calc(50% - 400px/2);
    border: 1px solid #0778B6;
  }
  .custom-span {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #0778B6;
  }
  
  
  .northstardynamictext {
    width: auto; 
    height: auto;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #141736;
  }
  
  .optionbox {
    box-sizing: border-box;
    width: 115px;
    height: 115px;
    text-align: center;
    align-items: center;

    text-overflow: ellipsis; 
    border: 1px solid #0778B6;
    border-radius: 10px; 
    margin: 3px; 
    margin-bottom: 15px; 
  }
  .optionboxtext {
    width: auto; 
    height: auto; 
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px; 
    line-height: 20px;
    text-align: center;
    color: #0778B6;
    overflow: hidden; /* Ensure no overflow */
    text-overflow: ellipsis; /* Ellipsis for overflowed text */
    font-weight: 800;
    display: flex;          
    justify-content: center; 
    align-items: center;    
    height: 40%; 
  }
    
  
  @media (max-width: 768px) {
    .box {
      width: 95%; /* Adjust box width for tablets */
      min-height: 178px; /* Slightly reduce the minimum height */
      padding: 12px;
      max-width: none;
    }
  
    .profile {
      width: 65px; /* Adjust profile size */
      height: 65px;
    }
  
    .name {
      font-size: 20px; /* Slightly smaller font size for tablets */
      left: 100px;
      line-height: 20px;
      text-overflow: ellipsis; /* Ellipsis for overflowed text */

    }
    .hide-scrollbar {
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For IE and Edge */
    }
    .northstartbox {
      max-width: 100%; 
      margin-left: 0; 
      padding: 5px;
      padding-right: 27px;
    }
  
    .northstardynamictext,
    .northstartext {
      font-size: 14px; 
      line-height: 20px;
    }

    .optionbox {
      width: 110px; 
      height: 110px; 
      border-radius: 8px; 
    }

    .optionboxtext {
      font-size: 14px; 
      line-height: 18px;
      font-weight: 700;
      display: flex;          
      justify-content: center; 
      align-items: center;    
      text-align: center;
      height: 40%; 
    }
  
  }
  
  /* Large Screens (Desktops and larger screens) */
  @media (max-width: 1024px) and (min-width: 769px) {
    .box {
      width: 95%; 
      max-width: none; 
      min-height: 180px;
      padding: 15px; 
    }
  
    .profile {
      width: 60px; 
      height: 60px;
    }
  
    .name {
      font-size: 24px; 
      left: 90px; 
      line-height: 24px;
    }
  
    .northstartbox {
      max-width: 100%; 
      margin-left: 0; 
      padding: 10px; 
    }
  
    .northstardynamictext,
    .northstartext {
      font-size: 16px; 
      line-height: 22px;
    }
  
    .optionbox {
      width: 140px; 
      height: 140px;
      border-radius: 10px; 
    }
  
    .optionboxtext {
      font-size: 18px; 
      line-height: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 40%;
    }
  }

  @media (max-width: 480px) {
    .optionbox {
        width: 108px; 
        height: 106px; 
        border-radius: 6px; 
        
    }
    .optionboxtext {
      font-size: 16px; 
      line-height: 1.2;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 600;
      display: flex;          
      justify-content: center; 
      align-items: center;    
      text-align: center;
      height: 40%; 
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.custom-dot-list-style {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.custom-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #D1D5DB;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-dot.active {
  background: #0778B6;
}