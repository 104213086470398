@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-next:after,  .swiper-button-prev:after{
    font-size: 10px!important;
  }

  .avatar.offline:before {
    content: "";
    position: absolute;
   background-color: #2EBB5E;
   height: 10px;
   width: 10px;
   border-radius: 50%;
   top: 38px;
   left: 38px;
}

/* Custom CSS for black placeholder text */
.custom-placeholder::placeholder {
  color: black; /* Or use #000 for the hex color code */
  opacity: 1; /* Ensure the opacity is set to 1 for full visibility */
}

.h1class{
  font-size: 32px;
  color: #0778B6;
  font-weight: bold;
}

@media (min-width:320px) { .createContainer{
padding:30px 0%;
margin: 0 34px;
} }
@media (min-width:481px) {.createContainer{
padding:30px 0%;
margin: 0 34px;
} }
@media (min-width:641px) { .createContainer{
padding:30px 12%
} }
@media (min-width:961px) { .createContainer{
padding:30px 20%
} }
@media (min-width:1025px) { .createContainer{
padding:30px 20%
} }
@media (min-width:1281px) {.createContainer{
padding:30px 20%
} }
.createJourneyContainer {
display: flex;
flex-direction: column;
}

@media (min-width:320px) { .h1class{
  margin-left: 20px;
} }
@media (min-width:481px) {.h1class{
  margin-left: 20px;
} }
@media (min-width:641px) { .h1class{
  margin-left: 56px;
} }
@media (min-width:961px) { .h1class{
  margin-left: 22px;
} }
@media (min-width:1025px) { .h1class{
  margin-left: 40px;
} }
@media (min-width:1281px) {.h1class{
  margin-left: 60px;
} }


@media (min-width:320px) { .labelSection{
margin: 0px 0 270px 0px;
} }
@media (min-width:481px) {.labelSection{
margin: 0px 0 270px 0px;
} }
@media (min-width:641px) { .labelSection{
margin:0px 0 270px 0px;
} }

/* Add this to your CSS file */
.progress-container {
width: 100%;
background-color: #e5e5e5; /* Light gray */
border-radius: 999px;
height: 16px;
overflow: hidden;
}

.progress-bar {
height: 100%;
background-color: #0778B6; /* Blue */
border-radius: 999px;
transition: width 0.3s ease; /* Smooth animation */
}
.checkbox-alias{
background-color: #ffffff;
display: inline-block;
width: 100%;
border: 1.5px solid rgb(130, 130, 130);
height: auto;
z-index: 1;
position: relative;
transition: all 250ms ease-out;
cursor: pointer;
margin: 4px 0;
padding: 10px;
}
.checkbox-alias-radio{
background-color: #ffffff;
display: inline-block;
width: 100%;
border: 1.5px solid rgb(130, 130, 130);
min-height: 45px;
z-index: 1;
position: relative;
transition: all 250ms ease-out;
cursor: pointer;
margin: 4px 0;
padding: 10px;
height: auto; 
word-wrap: break-word; 
overflow-wrap: break-word; 
}

.invisible-checkboxes input[type=checkbox]{
display: none;
height: auto;

}
.invisible-radio input[type=radio]{
display: none;
height: auto;

}

.invisible-checkboxes input[type=checkbox]:checked + .checkbox-alias{
background-color: #F5FCFE;
height: auto;
border: 1.5px solid #0778B6;
font-weight: bold;
}
.invisible-radio input[type=radio]:checked + .checkbox-alias-radio{
background-color: #F5FCFE;
border: 1.5px solid #0778B6;
font-weight: bold;
}
.invisible-dropdown{
background-color: #F5FCFE !important;
border: 1.5px solid #0778B6 !important;
font-weight: bold !important;
padding: 10px !important;
margin-right: 10px;
}

.min-val{
inset-inline-start: 1px;
  margin: 30px 0;
}
.max-val{
  margin: 30px -50px;
}
.accent-range {
accent-color: #0778B6 !important;
}
.accent-range-bg {
background-color: #F5FCFE !important;
}
.float-end{
float: inline-end;
}

.footer{
position: fixed; 
bottom: 0;
left: 0px;
right: 0px;
z-index: 1;
background-color: white;
}

.headerclass{
color: #0778B6;
font-family: sans-serif;
font-style: italic;
}

@media (min-width:320px) { .create-journey-button{
text-align: center;
margin-top: 55px;
} }
@media (min-width:481px) {.create-journey-button{
text-align: center;
margin-top: 55px;
} }
@media (min-width:641px) { .create-journey-button{
text-align: center;
margin-top: 55px;
/* padding-right: 78px; */
} }
@media (min-width:961px) { .create-journey-button{
text-align: center;
margin-top: 55px;
/* padding-right: 78px; */
} }
@media (min-width:1025px) { .create-journey-button{
text-align: center;
margin-top: 55px;
/* padding-right: 78px; */
} }
@media (min-width:1281px) {.create-journey-button{
text-align: center;
margin-top: 55px;
/* padding-right: 78px; */
} }

.disabled-btn-color{
color:#828282 !important;
background-color:#D9D9D9 !important;
}
.enable-btn-color{
background-color:#0778B6 !important;
}

.border-l-20{
border-left-width: 20px;
}

.btnellipsis{
  white-space: nowrap;
  width: 180px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  padding: 10px 24px !important;
}
