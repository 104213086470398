:root {
    --common-width: 700px;
    --common-height: auto;
    --medium-width: 650px; /* For medium screens (tablets) */
    --medium-height: auto; /* For medium screens */
    --large-width: 900px; /* For large screens (desktops) */
    --large-height: auto; /* For large screens */
  }

.custombox {
background: #FFFFFF;
border-radius: 6px;
height: var(--common-height);
width: var(--common-width);
}

.boxicon_desktop{
    width: 42px;
    height: 42px;
}

.customviewbox{
box-sizing: border-box;
width: 820px;
height: var(--common-height);
max-width:1820px;
max-height: 827px;
border: 1px solid #999999;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
border-radius: 6px;
}
.optionboxtextDesk{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px; 
    text-overflow: ellipsis; /* Ellipsis for overflowed text */
    color: #0778B6;
    display: flex;          
    justify-content: center; 
    align-items: center;    
    text-align: center;
    height: 40%; 
}
.profilebox{
width: var(--common-width);
height: 120px;
background: #ECFAFD;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
}
.northstar{
width: 25px;
height: 25px;
}
.customname {
    display: flex;
    align-items: center; 
    width: auto;
    height: 50px;
    gap: 0px;
    opacity: 1;
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 100;
    line-height: 29.14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.userNorthStar{
width: 400px;
height: auto;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 450;
font-size: 15px;
line-height: 20px;
color: #141736;
padding-right: 20px;
}
.customlinePortfolio {
    width: 1.5px;
    height: calc(100% - 30px);
    background-color: #737373;
    position: relative;
    margin: 20px 40px 20px 10px;
    align-self: stretch;
}

.customActionBox{
box-sizing: border-box;
width: 124px;
height: 120px;
border: 1px solid #0778B6;
border-radius: 10px;
margin-left: 7px;
}
.iframecomp{
    min-height: 480px;
}

@media screen and (min-width: 1500px) {
    :root {
        --common-width: var(--large-width);
        --common-height: var(--large-height);
    }
    .custombox {
        width: var(--large-width);
        height: var(--large-height);
    }
    .profilebox {
        width: var(--large-width)-2px;
        height: 120px; /* Adjust profile box height */
    }
    .customviewbox {
        width: 100%;
        height: var(--common-height);
    }
    .iframecomp{
        min-height: 640px;
    }
}

@media screen and (min-width: 1400px) {
  .userNorthStar {
    width: 500px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .custombox {
        height: 560px;
    }
    .customviewbox {
        height: 560px;
    }
    .iframecomp{
        min-height: 530px
    }
}