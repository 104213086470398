.certificate-reader {
  min-height: 100vh;
}

.certificates-container {
  padding: 2rem 4rem;
}

.certificates-container.mobile {
  padding: 1rem;
}

.certificates-wrapper {
  margin: 0 auto;
}

.mobile .page-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.certificates-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.mobile .certificates-grid {
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (max-width: 1024px) {
  .certificates-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .certificates-grid {
    grid-template-columns: 1fr;
  }
}

.certificate-card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease;
}

.card-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.certificate-title {
  font-size: 1.25rem;
  color: #3b82f6;
  font-family: 'Noto Sans', sans-serif;
  flex-grow: 1;
  font-weight: 500;
  text-shadow: 0.02em 0 0 currentColor;
}

.certificate-info {
  margin-top: 1rem;
}

.certificate-details,
.skills,
.issued-by,
.authorized-by,
.certificate-id {
  font-size: 0.875rem;
  color: #6b7280;
  font-weight: 500;
}

.certificate-details span,
.skills span,
.issued-by span,
.authorized-by span,
.certificate-id span {
  color: #1f2937;
  font-weight: 600;
}

.certificate-link {
  color: #3b82f6;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.certificate-link:hover {
  color: #2563eb;
}

.explore-section {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.mobile .explore-section {
  justify-content: center;
}

.explore-btn {
  padding: 0.75rem 1.5rem;
  background-color: rgb(2 132 199);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.explore-btn:hover {
  background-color: #2563eb;
}

.no-certificates {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;
  margin-bottom: 20px;
}

.mobile .no-certificates {
  margin-top: 10rem;
}

.no-certificates-text {
  color: #6b7280;
  font-size: 1.125rem;
  font-weight: 500;
  text-shadow: 0.02em 0 0 currentColor;
}

.error {
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}