/* Rectangle 26 - Main Box */
.box_college_mobile {
  position: relative;
  width: 98%; /* Adjust width to 98% of the parent container */
  max-width: 407px; /* Maximum width to retain the original size */
  height: auto; /* Adjust height based on content */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  top: 10px;
  background: #ecfafd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 15px; /* Add some padding inside the box */
  box-sizing: border-box; /* Ensure padding is included in width and height calculation */
  transition: height 0.3s ease; /* Smooth height transition */
  overflow-y: auto;
}

.box_college_mobile::-webkit-scrollbar {
  display: none;
}
.box_college_mobile {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}


.box_college_mobile.collapsed {
  min-height: 180px; /* Default height */
  max-height: 240px; /* Set a maximum height when collapsed */
  overflow: hidden; /* Hide overflowing content */
}

.box_college_mobile.expanded {
  height: auto; /* Adjust height dynamically */
  min-height: 260px; /* Ensure minimum height when expanded */
}

.boxicon_college_mobile {
  width: 36px;
  height: 36px;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin: auto; 
}


/* Profile - Circular image or icon */
.profile_college_mobile {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #D9D9D9;
  border-radius: 50%; /* Ensure the profile is circular */
}

/* Name */
.name_college_mobile {
  position: absolute;
  max-width: 200px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 21.06px;
  color: #000000;
}

.truncate-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  }


.line_college_mobile{
  position: absolute;
  width: 331px;
  height: 0px;
  left: calc(50% - 331px/2 + 0.5px);
  border: 1px solid #737373;

}
.northstartbox_college_mobile {
  width: 100%; /* Adjusts to container width */
  max-width: 337px; /* Keeps the original size limit */
  height: auto; /* Adjust height based on content */
  padding: 10px; /* Adds inner spacing for better visual appearance */
  box-sizing: border-box; /* Ensures padding doesn't affect dimensions */
}
.northstar_college_mobile{
  /* left: 39px; */
  bottom: 68.88%;
  box-shadow: 0px 0px 8.316px #FFFFFF;
}

.northstartext_college_mobile {
  width: auto; /* Adjust width dynamically */
  height: auto; /* Adjust height dynamically */
  opacity: 1; /* Fix opacity to make text visible */
  line-height: 25px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #141736;
}

.northstardynamictext_college_mobile {
  width: auto; 
  height: auto;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #141736;
}

.optionbox_college_mobile {
  box-sizing: border-box;
  width: 115px;
  height: 115px;
  text-align: center;
  align-items: center;

  text-overflow: ellipsis; 
  border: 1px solid #0778B6;
  border-radius: 10px; 
  margin: 3px; 
  margin-bottom: 15px; 
}
.optionboxtext_college_mobile {
  width: auto; 
  height: auto; 
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px; 
  line-height: 20px;
  text-align: center;
  color: #0778B6;
  overflow: hidden; /* Ensure no overflow */
  text-overflow: ellipsis; /* Ellipsis for overflowed text */
  font-weight: 800;
  display: flex;          
  justify-content: center; 
  align-items: center;    
  height: 40%; 
}
  

@media (max-width: 768px) {
  .box_college_mobile {
    width: 95%; /* Adjust box width for tablets */
    min-height: 178px; /* Slightly reduce the minimum height */
    padding: 12px;
  }

  .profile_college_mobile {
    width: 65px; /* Adjust profile size */
    height: 65px;
  }

  .name_college_mobile {
    font-size: 20px; /* Slightly smaller font size for tablets */
    left: 100px;
    line-height: 20px;
    text-overflow: ellipsis; /* Ellipsis for overflowed text */

  }
  .hide-scrollbar_college_mobile {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
  }
  .northstartbox_college_mobile {
    max-width: 100%; 
    margin-left: 0; 
    padding: 5px;
    padding-right: 27px;
  }

  .northstardynamictext_college_mobile,
  .northstartext_college_mobile {
    font-size: 14px; 
    line-height: 20px;
  }

  .optionbox_college_mobile {
    width: 110px; 
    height: 110px; 
    border-radius: 8px; 
  }

  .optionboxtext_college_mobile {
    font-size: 14px; 
    line-height: 18px;
    font-weight: 700;
    display: flex;          
    justify-content: center; 
    align-items: center;    
    text-align: center;
    height: 40%; 
  }

}

/* Large Screens (Desktops and larger screens) */
@media (min-width: 1024px) {
  .box_college_mobile {
    width: 407px; /* Keep full width on large screens */
    height: 178px; /* Fixed height for large screens */
    top: 186px; /* Maintain top position */
    padding: 20px; /* Extra padding for larger screens */
  }

  .profile_college_mobile {
    width: 42px; /* Maintain profile size */
    height: 42px;
  }

  .name_college_mobile {
    font-size: 18px; /* Default font size */
    left: 96px; /* Default left position */
  }
}

@media (max-width: 480px) {
  .optionbox_college_mobile {
      width: 108px; 
      height: 106px; 
      border-radius: 6px; 
      
  }
  .optionboxtext_college_mobile {
    font-size: 16px; 
    line-height: 1.2;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    display: flex;          
    justify-content: center; 
    align-items: center;    
    text-align: center;
    height: 40%; 
}
}

.hide-scrollbar::-webkit-scrollbar {
display: none;
}

.scroll-smooth {
scroll-behavior: smooth;
}

.custom-dot-list-style {
display: flex;
justify-content: center;
gap: 8px;
margin-top: 16px;
}

.custom-dot {
width: 8px;
height: 8px;
border-radius: 50%;
background: #D1D5DB;
cursor: pointer;
transition: all 0.3s ease;
}

.custom-dot.active {
background: #0778B6;
}